import React, { useRef, useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "../css/App.css";
import * as poseDetection from "@tensorflow-models/pose-detection";
import "@tensorflow/tfjs-backend-webgl";
import Webcam from "react-webcam";
import countDownStartVoice from "../audio/start_countdown.mp3";
import snapshotStartVoice from "../audio/please_stand.mp3";
import pleaseBeSittedVoice from "../audio/please_be_seated.mp3";
import weWillBeginVoice from "../audio/we_will_begin.mp3";
import countDownEndVoice from "../audio/end_countdown.mp3";
import beep from "../audio/beep2.mp3";

import { PosePredictor } from "../tsx/PosePredictor";
import { Exercise } from "../tsx/Exercise";
import { RightHandRaise } from "../tsx/RightHandRaise.Exercise";
import { LeftHandRaise } from "../tsx/LeftHandRaise.Exercise";
import { Keypoint } from "@tensorflow-models/pose-detection";
import { ExerciseConfig, Position } from "../models";
import { ExerciseService } from "../tsx/ExerciseService";
import { apiUrl, MAX_VIDEO_WIDTH } from "../tsx/Constant";
import { filter, takeWhile } from "rxjs";
import axios from "axios";
import { StandingPosition } from "../tsx/StandingPosition.Exercise";

const COUNTDOWN_SECS = 5;
const SNAPSHOT_SECS = 14;
const EXERCISE_TIMER_SECS = 30;
const POWER_COUNTER_SECS = 20;

let countdownTimerGlobal: number = COUNTDOWN_SECS;
let snapshotTimerGlobal: number = SNAPSHOT_SECS;
let exerciseTimerGlobal: number = EXERCISE_TIMER_SECS;
let isPowerUsedGlobal: boolean = false;
let countDownEndAudioPlayed: boolean = false;
let powerRepsGlobal: number = 0;
let exerciseStartTimeGlobal = new Date();
let repsGlobal: number = 0;
let resultsShownGlobal: boolean = false;
let timestampGlobal: { timestamp: string; position: string }[] = [];
let handRaiseTimestamps: { timestamp: string; position: string }[] = [];
let standingTimestamps: { timestamp: string; position: string }[] = [];
let repTimeStamps: { rep: number; timestamp: string }[] = [];
let showSkeletonGlobal = true;
let timeDiffGlobal = 0;
let prevTimeDiffGlobal = 0;
let globalData : {concentricTime: number; distance: number}[] = [];

function Tracker() {
  const webcamRef = useRef(null);
  const canvasRef = useRef(null);
  const location = useLocation();
  const navigate = useNavigate();

  // To Do: Consolidate these into one state object
  const [countDownStartAudio] = useState(new Audio(countDownStartVoice));
  const [pleaseBeSittedAudio] = useState(new Audio(pleaseBeSittedVoice));
  const [weWillBeginAudio] = useState(new Audio(weWillBeginVoice));

  const [snapshotStartAudio] = useState(new Audio(snapshotStartVoice));
  const [countDownEndAudio] = useState(new Audio(countDownEndVoice));
  const [beepAudio] = useState(new Audio(beep));

  const [predictorWidth, setPredictorWidth] = useState(MAX_VIDEO_WIDTH);
  const [predictorHeight, setPredictorHeight] = useState(MAX_VIDEO_WIDTH * 0.75);

  const [positionText, setPositionText] = useState("");
  const [raisedHandPosition, setRaisedHandPosition] = useState("");

  // User Detected
  const [userDetected, setUserDetected] = useState(false);
  const [statePredictor, setStatePredictor] = useState<PosePredictor | null>(null);

  // User Detected
  const [exerciseInfo, setExerciseInfo] = useState({
    countdown: 10,
    duration: 120,
    id: 2,
    name: "",
  });

  // Loading State
  const [loading, setLoading] = useState(true);
  const [uploading, setUploading] = useState(false);

  // Recording
  const [capturing, setCapturing] = useState(false);

  // Count Down Timer
  const [countdownStarted, setCountdownStarted] = useState(false);
  const [countDownTimer, setCountDownTimer] = useState(COUNTDOWN_SECS);

  // Snapshot Timer
  const [snapshotStarted, setSnapshotStarted] = useState(false);
  const [snapshotTimer, setSnapshotTimer] = useState(SNAPSHOT_SECS);

  // Exercise Timer
  const [timerStarted, setTimerStarted] = useState(false);
  const [exerciseTimer, setExerciseTimer] = useState(EXERCISE_TIMER_SECS);

  // Exercising
  const [showSkeleton, setShowSkeleton] = useState(showSkeletonGlobal);
  const [repCounter, setRepCounter] = useState(0);
  const [powerCounter, setPowerCounter] = useState({
    avgPower: 0,
    peakPower: 0,
  });
  const [exerciseName, setExerciseName] = useState("N/A");
  const [isPowerUsed, setIsPowerUsed] = useState(false);

  // Exercise Ended
  const [exerciseEnded, setExerciseEnded] = useState(false);
  const [resultsShown, setResultsShown] = useState(false);
  const [dataSent, setDataSent] = useState(false);

  const [user, setUser] = useState({
    weight: "",
    height: "",
    exerciseId: "",
    gender: "",
    firstName: "",
    lastName: "",
    email: "",
  });

  // Background color when full pbody is detected
  const [backgroundColor, setBackgroundColor] = useState("#282c34");

  // Exercise positions detected per repetition step
  let detectedPositions: string[] = [];
  let leftHandPositions: string[] = [];
  let rightHandPositions: string[] = [];
  let standingPositions: string[] = [];

  // Predict Exercise poses, positions and keypoints
  let predictor: PosePredictor | null | undefined = undefined;

  // Exercise state variables
  let weight: any, height: any, exerciseId: any, gender: any, frameId: number, firstName: any, lastName: any, email: any;

  // Page Load
  useEffect(() => {
    // Get Exercise state variables from location variable
    if (location.state) {
      const state: any = location.state;

      // Store Local Storage
      console.log("Setting local storage and user state:", state);
      setUser(state);
      localStorage.setItem("user", JSON.stringify(state));
    } else {
      // Store Local Storage
      let userString = localStorage.getItem("user");

      if (userString) {
        let user = JSON.parse(userString);

        console.log("getting user from local storage...", user);

        setUser({
          weight: user.weight,
          height: user.height,
          exerciseId: user.exerciseId,
          gender: user.gender,
          firstName: user.firstName,
          lastName: user.lastName,
          email: user.email,
        });
      }
    }
  }, [location.state]);

  // Window Resize

  const handleResize = () => {
    const H = window.innerHeight * 0.9;
    const W = H / (predictor?.reciprocal || 0.75);

    setPredictorWidth(W);
    setPredictorHeight(H);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // Get Exercise Data
  // useEffect(() => {
  //   if (!ExerciseService.exercises.length) {
  //     let apiurl = `${apiUrl}/exercise/list`;

  //     axios
  //       .get(apiurl)
  //       .then((response) => {
  //         console.log("Get Exercises response: ", response.data.data, exerciseId);
  //         ExerciseService.exercises = response.data.data;
  //       })
  //       .catch((err) => {
  //         console.log("Error getting exercises: ", err);
  //       });
  //   }
  // }, [exerciseId]);

  // Create snapShot Timer
  useEffect(() => {
    if (userDetected) {
      if (snapshotStarted) {
        console.log("snapshotStarted Started", snapshotTimer);
        const intervalSnapshot = setInterval(() => {
          setSnapshotTimer((prevCounter: number) => {
            const newSnapshotCounter = prevCounter > 0 ? prevCounter - 1 : 0;
            snapshotTimerGlobal = newSnapshotCounter;
            // console.log("Snapshot audio", prevCounter);

            // if (prevCounter === 14) {
            //   // console.log("Playing audio");
            //   snapshotStartAudio.play();
            // }
            if (newSnapshotCounter < 4) {
              // console.log("snap taken for femur: ", newSnapshotCounter);
              snapDisplacement();
            }

            if (newSnapshotCounter < 1) {
              // console.log("snapshotStarted stopped: ", newSnapshotCounter);
              setSnapshotStarted(false);
              setCountdownStarted(true);
            }

            // console.log("snapshot timer: ", newSnapshotCounter);
            return newSnapshotCounter;
          });
        }, 1000);

        return () => {
          // console.log("Clearing Snapshot Interval");
          clearInterval(intervalSnapshot);
        };
      }
    }
  }, [snapshotTimer, snapshotStarted, countDownTimer, userDetected, snapshotStartAudio, capturing, statePredictor]);

  // Create Countdown Timer
  useEffect(() => {
    if (userDetected) {
      if (countdownStarted) {
        const intervalCountdown = setInterval(() => {
          setCountDownTimer((prevCounter: number) => {
            const newCounter = prevCounter > 0 ? prevCounter - 1 : 0;
            countdownTimerGlobal = newCounter;

            const SIT_STAND_EXERCISE_ID = "1";
            const MARCH_IN_PLACE_EXERCISE_ID = "2";

            let exerciseId = user.exerciseId;

            if (exerciseId === SIT_STAND_EXERCISE_ID && prevCounter === SNAPSHOT_SECS) {
              console.log("Playing default sit stand audio");
              pleaseBeSittedAudio.play();
            }
            if (exerciseId === MARCH_IN_PLACE_EXERCISE_ID && prevCounter === 10) {
              console.log("Playing default march in place audio");
              weWillBeginAudio.play();
            }

            if (exerciseId !== SIT_STAND_EXERCISE_ID && exerciseId !== MARCH_IN_PLACE_EXERCISE_ID && prevCounter === 10) {
              console.log("Playing default audio", exerciseId, prevCounter);
              // countDownStartAudio.play();
            }

            return newCounter;
          });
        }, 1000);

        return () => {
          // console.log("Clearing Countdown Interval");
          clearInterval(intervalCountdown);
        };
      }
    }
  }, [pleaseBeSittedAudio, user.exerciseId, countdownStarted, countDownTimer, userDetected, countDownStartAudio, capturing, statePredictor]);

  // Start Exercise Timer when Countdown Timer ends
  useEffect(() => {
    if (countDownTimer <= 0) {
      // Stop the countdown
      setCountdownStarted(false);

      // Start the exercise timer
      setTimerStarted(true);

      exerciseStartTimeGlobal = new Date();
      console.log("exerciseStartTimeGlobal: ", exerciseStartTimeGlobal);

      if (!capturing) {
        console.log("Start Recording...");
        setCapturing(true);
        statePredictor?.startRecording();
      }
    }
  }, [countDownTimer]);

  // Stop Exercise Timer when ended
  useEffect(() => {
    // Exercise timer has ended
    if (exerciseTimer <= 0 && exerciseEnded) {
      // Stop the exercise timer
      setResultsShown(true);
      if (statePredictor) statePredictor.displacement = null;

      try {
        if (!resultsShownGlobal) {
          // Save exercise results
          const results = {
            firstName: user.firstName,
            lastName: user.lastName,
            email: user.email,
            gender: user.gender,
            weightLbs: user.weight,
            weightKg: convertPoundsToKilograms(Number(user.weight)),
            height: user.height,
            totalReps: repCounter,
            exerciseId: user.exerciseId,
            avgPower: powerCounter.avgPower,
            peakPower: powerCounter.peakPower,
            powerReps: powerRepsGlobal,
            timestamps: timestampGlobal,
            repTimestamps: repTimeStamps,
            handRaiseTimestamps: handRaiseTimestamps,
            recorded: new Date().valueOf(),
          };

          if (!dataSent) {
            sendResults(results);
          }
        }

        resultsShownGlobal = true;
      } catch (e) {
        console.log("Error sending results: ", e);
      }
    }
  }, [exerciseTimer, exerciseEnded, powerCounter.avgPower, powerCounter.peakPower, statePredictor, user, timerStarted, repCounter]);

  // Create Exercise Timer
  useEffect(() => {
    // console.log("Exercise Started", timerStarted);
    if (timerStarted) {
      const interval = setInterval(() => {
        setExerciseTimer((prevCounter: number) => {
          // Get difference between now and exercise start time
          const now = new Date();
          exerciseTimerGlobal = exerciseInfo.duration - (now.getTime() - exerciseStartTimeGlobal.getTime()) / 1000;
          let newExerciseCounter = Math.max(Math.ceil(exerciseTimerGlobal), 0);

          // Exercise timer is ending
          if (newExerciseCounter < 5 && !countDownEndAudioPlayed) {
            // console.log("Playing End audio");
            // countDownEndAudio.play();
            countDownEndAudioPlayed = true;
          }

          return newExerciseCounter;
        });
      }, 1000);

      return () => clearInterval(interval);
    }
  }, [exerciseInfo, timerStarted, exerciseTimer, countDownEndAudio]);

  // End Exercise
  useEffect(() => {
    if (exerciseTimer > 0) {
      // console.log("Exercise Timer", exerciseTimer);
    } else {
      console.log("Exercise Ended");
      console.log("Data: ", globalData);
      setExerciseEnded(true);

      if (capturing) {
        console.log("Stop Recording...");
        setCapturing(false);
        statePredictor?.stopRecording();
      }
    }
  }, [exerciseTimer, capturing, statePredictor]);

  // Create Pose Detector using MoveNet
  useEffect(() => {
    const model = poseDetection.SupportedModels.MoveNet;

    if (location.state === null) {
      navigate("/");
      return () => {};
    } else {
      console.log("got pose detection: ", poseDetection);

      const exerciseAndConfig = buildExercise(location.state);
      const exercise: Exercise = exerciseAndConfig.data;
      const exerciseConfig = exerciseAndConfig.config;

      console.log("Got Exercise: ", exercise, exerciseConfig);

      setExerciseInfo({
        countdown: exercise.countdown,
        duration: exercise.duration,
        id: exercise.id,
        name: exercise.name,
      });

      localStorage.setItem("exercise", JSON.stringify(exerciseConfig));

      setExerciseName(exercise.name);
      setExerciseTimer(exercise.duration);
      setCountDownTimer(exercise.countdown);

      poseDetection.createDetector(model).then(detect);

      return () => {
        // Anything in here is fired on component unmount.
        if (predictor) {
          predictor.detector.dispose();
          predictor = null;
          cancelPredictions();
        }
      };
    }
  }, []);

  useEffect(() => {
    console.log("Predictor Changed: ", predictor);
  }, [predictor]);

  const buildExercise = (user: any) => {
    // Check exercise cache if exercises are not loaded
    if (!ExerciseService.exercises || (ExerciseService.exercises && ExerciseService.exercises.length === 0)) {
      let configCache = ExerciseService.loadExerciseFromCache();
      console.log("Got Exercise from Cache: ", configCache);

      if (configCache) {
        return { config: configCache, data: new Exercise(configCache as ExerciseConfig) };
      }
    }

    let config = (ExerciseService.exercises.find((e) => e.id === Number(user.exerciseId)) as ExerciseConfig) || { steps: "" };

    return { config, data: new Exercise(config) };
  };

  // Stop rendering keypoint predictions
  const cancelPredictions = () => cancelAnimationFrame(frameId);

  //
  const checkIfLoading = (snapshot: any) => {
    // Wait until a position is  detected and loaded
    if (snapshot) {
      setLoading(false);
    } else {
      setLoading(true);
    }
  };

  const handleShowSkeletonChange = (event: any) => {
    showSkeletonGlobal = event.target.checked;
    setShowSkeleton(showSkeletonGlobal);
    // console.log('show skeleton from handle fn:', showSkeleton, showSkeletonGlobal);
  };

  // Check for a Hand Raise
  const checkHandRep = (position: string, steps: string[], whichHand: string = "right") => {
    if (whichHand === "right") {
      rightHandPositions = logHandRep(rightHandPositions, position, steps);
    }
    if (whichHand === "left") {
      leftHandPositions = logHandRep(leftHandPositions, position, steps);
    }
  };

  // Check for a Hand Raise
  const calcMidFemur = (position: string, steps: string[]) => {
    standingPositions = logStandingRep(standingPositions, position, steps);
  };

  const logStandingRep = (positionsLogged: string[], position: string, steps: string[]): string[] => {
    // console.log("logStandingRep?");

    // Successful Position Detected
    if (steps[positionsLogged.length] === position) {
      positionsLogged = [...positionsLogged, position];
      // console.log("logStandingRep Successful Position Detected: ", positionsLogged);
    }
    // Successful Rep Detected
    if (positionsLogged.length === steps.length) {
      const newEpochTime = new Date();
      const timestamp = (newEpochTime.getTime() - exerciseStartTimeGlobal.getTime()) / 1000 + " secs";

      // console.log("logStandingRep Standing Detected: ", newEpochTime, exerciseStartTimeGlobal, position, timestamp);

      snapDisplacement();

      standingTimestamps.push({ timestamp, position });
      positionsLogged = [];
    }

    return positionsLogged;
  };

  // Check Average Keypoint Score
  const checkKeypointsScore = (pose: poseDetection.Pose) => {
    const avg: number = pose.keypoints.reduce((a: number, b: Keypoint) => a + (b.score as number), 0) / pose.keypoints.length;

    const minKeypoint = pose.keypoints.reduce((a: number, b: Keypoint) => {
      const score = b.score as number;
      return a < score ? a : score;
    }, 1);

    // console.log("minKeypoint: ", minKeypoint)
    // if (avg > 0.5) { // average keypoint score is above 50%
    if (minKeypoint > 0) {
      // if (minKeypoint > 0.2) {
      // min keypoint score is above 30%
      setBackgroundColor("#32d416");
      if (!userDetected) setUserDetected(true); // assume user is in frame
      return true;
    } else {
      setBackgroundColor("#282c34");
      if (userDetected) setUserDetected(false);
      return false;
    }
  };

  // Check Exercise steps for repetitions
  const checkRep = (position: string, steps: string[], pose: poseDetection.Pose) => {
    // let timeDiff = 0; 
    // console.log(`Position: `, position);
    if (steps[detectedPositions.length] === position || steps[detectedPositions.length].indexOf(position) > -1) {
      // Position detected for current repetition step
      const now = new Date();
      const diffTime = now.getTime() - exerciseStartTimeGlobal.getTime();
      const diffSecs = Number(diffTime / 1000).toFixed(2);
      const timestamp = diffSecs + " secs"; 

      timestampGlobal.push({ timestamp, position });
      detectedPositions = [...detectedPositions, position]; // Add detected position to array
      // beepAudio.play();
    }

    if (detectedPositions.length === steps.length) {
      // Full repetition detected
      setRepCounter((prevRepCounter: number) => {
        const newReps = prevRepCounter + 1;
        repsGlobal = newReps;
        return newReps;
      });

      prevTimeDiffGlobal = timeDiffGlobal;
      timeDiffGlobal = (new Date().getTime() - exerciseStartTimeGlobal.getTime()) / 1000;
      const timestamp = timeDiffGlobal + " secs";
      var powerTimeDiff = (timeDiffGlobal - prevTimeDiffGlobal)/3;
      
      
       
      console.log("Rep Detected: ", timestamp);
      repTimeStamps.push({ rep: repsGlobal, timestamp });   
// && exerciseTimerGlobal > EXERCISE_TIMER_SECS - POWER_COUNTER_SECS
      if (isPowerUsedGlobal) {
        // console.log("Power Used: ", exerciseTimerGlobal);

        setPowerCounter((prevPowerCounter: { avgPower: number; peakPower: number }) => {
          let weight = Number(convertPoundsToKilograms((location.state as any).weight));
          // console.log("Height: ", (location.state as any).height)

          let height = Number(convertFeetToMeters((location.state as any).height))
          console.log("Calculate Power Reps: ", repsGlobal, Number(weight));

          powerRepsGlobal = repsGlobal;

          var dist = Exercise.calculateDistance(repsGlobal, height, pose);
          // console.log("power avg: ", power.avgPower);
          // (pose.LhipBefore - pose.LhipAfter)(user.height / pose.Lear - pose.lAnkle)
          // return { avgPower: power.avgPower, peakPower: power.peakPower };
          
          console.log("Midfemur length: ", dist);
          // const accel = (2 * dist)/(timeDiff ** 2);
          console.log("Time diff for rep: ", powerTimeDiff);
          dist = 0.77982-0.498252 * dist;
          powerTimeDiff = 0.352845+0.410776 * powerTimeDiff;
          
          const vel = dist / powerTimeDiff;
          globalData.push({concentricTime: powerTimeDiff, distance: dist});

          const power = Math.round(weight * (9.81) * vel);
          // console.log("Power: ", power);
          if (power > 2000){
            return { avgPower: prevPowerCounter.avgPower, peakPower: prevPowerCounter.peakPower }
          }
          if (prevPowerCounter.avgPower === 0 && prevPowerCounter.peakPower === 0) {
            return { avgPower: power, peakPower: power };
          }
          return { avgPower: (Math.round((prevPowerCounter.avgPower) * ((repsGlobal - 1)/repsGlobal) + power / repsGlobal)), peakPower: Math.max(prevPowerCounter.peakPower, power) };

        });
      }

      detectedPositions = []; // Clear detected positions array
    }
  };

  // Convert LBS to KG
  const convertPoundsToKilograms = (pounds: number): number => {
    const kilograms = pounds * 0.45359291;
    return kilograms;
  };

  const convertFeetToMeters = (height: string): number => {
    // console.log("Type of height: ", typeof height);
    let arrHeight = height.replace(/(['"])/g, "").split(" ").map(Number)
    let meters = arrHeight[0] * .3048 + arrHeight[1] *.0254;
    return meters;
  };

  // Detect function initialises the PosePredictor
  const detect = (detector: poseDetection.PoseDetector) => {
    // console.log("got detector: ", detector);
    const config = {
      model: poseDetection.SupportedModels.MoveNet,
      maxPoses: 1,
      type: "lightning",
      scoreThreshold: 0.3,
      customModel: "",
      enableTracking: false,
      lineWidth: 1,
      keypointRadius: 6,
    };
    predictor = predictor === null ? null : new PosePredictor(webcamRef, canvasRef, detector, config);
    predictor?.ratioReady.subscribe(() => handleResize());
    predictor?.sendResult.pipe(takeWhile(() => predictor !== null)).subscribe(async (result) => {
      console.log(
        "sending result: ",
        predictor?.recording &&
          predictor?.result && {
            video: predictor.recording,
            result: predictor.result,
          }
      );
      if (predictor?.recording && predictor?.result && predictor?.result.id) sendVideoResults();
    });
    setStatePredictor(predictor);
    renderPredictions();
  };

  // Prints Results to the console
  const sendVideoResults = () => {

    console.log("Sending Video Exercise Results");
    let apiurl = `${apiUrl}/exercise/video`;

    var formData = new FormData();
    formData.append("videoFile", predictor?.recording!, "video.mp4");
    formData.append("results", JSON.stringify(predictor?.result));

    axios
      .post(apiurl, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        console.log("Posted Video Results to api: ", response.data);
        statePredictor && (statePredictor.recording = null as any);
        statePredictor && (statePredictor.result = null);
        predictor?.result && (predictor.result = null);
        predictor?.recording && (predictor.recording = null as any);

        setUploading(false);
        console.log("Cleared predictor: ", predictor, statePredictor);
        console.log("Done uploading.");
      })
      .catch((err) => {
        console.log("Error Posting Exercise resutls: ", err);
        statePredictor && (statePredictor.result = null);
        setUploading(false);
        console.log("Done uploading.");
      });
  };

  // Prints Results to the console
  const sendResults = (results: any) => {
    console.log("Exercise Results: ", results);
    let apiurl = `${apiUrl}/exercise/results`;

    setUploading(true);
    console.log("uploading...");
    axios
      .post(apiurl, results)
      .then((response) => {
        console.log("Posted Results to api: ", response.data);
        setDataSent(true);
        const { id, results } = response.data;
        if (statePredictor) {
          statePredictor.result = { id, ...results };
          statePredictor.registerResult.next(true);
        }
      })
      .catch((err) => {
        setDataSent(true);
        console.log("Error Posting Exercise resutls: ", err);
      });
  };

  // Detect a Hand Raise in real time based on pose keypoints
  const detectHandRaise = (x: Exercise, pose: { keypoints: Keypoint[] }, hand: string) => {
    // If exercise counting down, detect position
    if (exerciseTimerGlobal > 0 && countdownTimerGlobal === 0) {
      // Detect Hand Raise Position
      for (const detectable of x.positions) {
        const position = detectPosition(pose, detectable, x.displacement || null, false);
        if (position.detected) checkHandRep(position.name, x.steps, hand);
      }
    }
  };

  // Detect a Standing in real time based on pose keypoints
  const detectStandingPosition = (exercise: Exercise, pose: { keypoints: Keypoint[] }) => {
    // If exercise counting down, detect position
    if (exerciseTimerGlobal > 0 && countdownTimerGlobal === 0) {
      // Detect Standing  Position
      for (const detectable of exercise.positions) {
        const position = detectPosition(pose, detectable, exercise.displacement || null, false);
        if (position.detected) calcMidFemur(position.name, exercise.steps);
      }
    }
  };

  // Detect Exercise position based on pose keypoints
  const detectPosition = (pose: { keypoints: Keypoint[] }, position: Position, displacement: poseDetection.Pose | null, showPositionText: boolean = false): Position => {
    // Discard position with low confidence

    position.detected = Exercise.constraintCheck(pose.keypoints, position.constraints, displacement);

    // console.log(" position.detected: ",  position.detected, position.name, position);
    // Show Position Text On Screen
    if (showPositionText) {
      if (position.detected) {
        if (positionText !== position.name) {
          setPositionText(position.name);

          // const newEpochTime = new Date();
          // const timestamp = (newEpochTime.getTime() - exerciseStartTimeGlobal.getTime()) / 1000 + " secs";

          // console.log("New position detected:",
          //   position.name,
          //   (new Date().valueOf() - exerciseStartTimeGlobal) / 1000 + ' secs'
          // );
        }
      } else {
        if (positionText !== "") {
          setPositionText("");
        }
      }
    }

    return position;
  };

  // Detect Exercise steps in real time based on keypoints
  const detectSteps = (x: Exercise, pose: poseDetection.Pose) => {
    // console.log("detecting steps: ", x, pose);
    const isFrameValid = checkKeypointsScore(pose);

    // let newTimer: any = countdownTimerGlobal;
    // console.log("checking for exercise", exerciseTimer, countdownTimerGlobal);

    if (exerciseTimerGlobal > 0 && countdownTimerGlobal === 0 && isFrameValid) {
      // Detect Position
      for (const detectable of x.positions) {
        const position: Position = detectPosition(pose, detectable, x.displacement || null, true);

        if (position.detected) {
          // console.log("Position Detected: ", position.name, x.steps)
          checkRep(position.name, x.steps, pose);
        }
      }
    }
  };

  const logHandRep = (positionsLogged: string[], position: string, steps: string[]): string[] => {
    // Successful Position Detected
    if (steps[positionsLogged.length] === position) {
      positionsLogged = [...positionsLogged, position];
    }

    // Successful Rep Detected
    if (positionsLogged.length === steps.length) {
      const newEpochTime = new Date();
      const timestamp = (newEpochTime.getTime() - exerciseStartTimeGlobal.getTime()) / 1000 + " secs";

      console.log("Hand Raise Detected: ", newEpochTime, exerciseStartTimeGlobal, position, timestamp);

      // Remove after 3 seconds
      setRaisedHandPosition(position);
      setTimeout(() => {
        setRaisedHandPosition("");
      }, 3000);

      handRaiseTimestamps.push({ timestamp, position });
      positionsLogged = [];
    }

    return positionsLogged;
  };

  // Render keypoint predictions in real time using display refresh rate
  const renderPredictions = async () => {
    if (predictor) {
      // console.log("got predictor: ", predictor);

      // Get the current positions
      const snapshot = (await predictor.produceSnapshot()) as poseDetection.Pose[];

      // Get positions (e.g., head looking downward left, head facing forward, etc.)
      // const position = await predictor.predictPose(snapshot);
      // console.log('position: ', position && position[0]?.name);

      // check If Loading
      checkIfLoading(snapshot);

      // If showSkeleton is true, show the skeleton key points
      if (showSkeletonGlobal) predictor.drawResults(snapshot);
      else predictor.clearCtx();

      // console.log('show skeleton from renderPredictions: ', showSkeletonGlobal);

      // console.log("renderPredictions user: ", user);
      // Get Exercise (e.g., Sit / Stand)
      const exercise = buildExercise(location.state).data;
      const rightHand = new RightHandRaise();
      const leftHand = new LeftHandRaise();
      const standing = new StandingPosition();

      setIsPowerUsed(exercise.isPowerUsed);
      isPowerUsedGlobal = exercise.isPowerUsed;

      if (predictor.displacement) {
        exercise.displacement = predictor.displacement;
        // console.log('xrsz displacement: ', x.displacement, PosePredictor.data(x.displacement));
      }

      if (snapshot && snapshot[0]) {
        detectSteps(exercise, snapshot[0]);
        detectHandRaise(rightHand, snapshot[0], "right");
        detectHandRaise(leftHand, snapshot[0], "left");
        detectStandingPosition(standing, snapshot[0]);
      }

      frameId = requestAnimationFrame(renderPredictions);
    }
  };

  function formatTimer(exerciseTimer: any) {
    let minutes = Math.floor(exerciseTimer / 60);
    let seconds = exerciseTimer % 60;
    return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
  }

  function reset() {
    console.log("reset");
    const exercise: Exercise = buildExercise(user).data;
    exerciseStartTimeGlobal = new Date();
    timestampGlobal = [];
    handRaiseTimestamps = [];
    standingTimestamps = [];
    resultsShownGlobal = false;
    powerRepsGlobal = 0;
    countDownEndAudioPlayed = false;
    snapshotTimerGlobal = SNAPSHOT_SECS;
    detectedPositions = [];

    setDataSent(false);
    setExerciseTimer(exercise.duration);
    setCountDownTimer(exercise.countdown);
    setSnapshotStarted(false);
    setSnapshotTimer(SNAPSHOT_SECS);
    setCountdownStarted(false);
    setTimerStarted(false);
    setCountdownStarted(false);
    setUserDetected(false);
    setPowerCounter({ avgPower: 0, peakPower: 0 });
    setRepCounter(0);
  }

  function startTimer() {
    setSnapshotStarted(true);
  }

  const snapDisplacement = () => {
    let displacementPredictor = statePredictor || predictor;
    // console.log("snapDisplacement", predictor)
    displacementPredictor?.displace().then(
      () => {
        // console.log("New Displacement Snapshot: ", displacementPredictor?.displacement);
      },
      (err) => {
        console.log("snapDisplacement Error: ", err);
      }
    );
  };

  return predictor === null ? null : (
    <div className="App">
      {loading ? (
        <div className="loading">
          <div className="spinner-border text-light" role="status">
            <span className="sr-only"></span>
          </div>
        </div>
      ) : null}

      {snapshotStarted ? (
        <div className="App-overlay">
          {userDetected ? (
            <div className="countdown-hint">
              Please stand with arms crossed.<br></br>
              {snapshotTimer}
            </div>
          ) : (
            <div className="countdown-hint">Please get into position...</div>
          )}
        </div>
      ) : null}
      {uploading ? (
        <div className="App-overlay">
          <div className="countdown-hint raised">Recording results...</div>
          <div className="loading">
            <div className="spinner-border text-light" role="status">
              <span className="sr-only"></span>
            </div>
          </div>
        </div>
      ) : null}
      {countdownStarted && !snapshotStarted && user.exerciseId === "1" ? <div className="App-overlay">{userDetected && countDownTimer > 5 ? <div className="countdown-hint">Please be seated...</div> : <div className="countdown-number">{countDownTimer}</div>}</div> : null}
      {countdownStarted && !snapshotStarted && user.exerciseId === "2" ? <div className="App-overlay">{userDetected && countDownTimer > 5 ? <div className="countdown-hint">We will begin shortly...</div> : <div className="countdown-number">{countDownTimer}</div>}</div> : null}
      {countdownStarted && !snapshotStarted && user.exerciseId !== "1" && user.exerciseId !== "2" ? <div className="App-overlay">{userDetected ? <div className="countdown-number">{countDownTimer}</div> : <div className="countdown-hint">Please get into position...</div>}</div> : null}

      <header
        className="App-header"
        style={{
          backgroundColor: backgroundColor,
          transition: "all .5s ease",
          WebkitTransition: "all .5s ease",
          MozTransition: "all .5s ease",
        }}
      >
        <Webcam
          ref={webcamRef}
          style={{
            position: "absolute",
            marginLeft: "auto",
            marginRight: "auto",
            left: 0,
            right: 0,
            textAlign: "center",
            zIndex: 9,
            width: predictorWidth,
            height: predictorHeight,
          }}
        />

        <canvas
          ref={canvasRef}
          style={{
            position: "absolute",
            marginLeft: "auto",
            marginRight: "auto",
            left: 0,
            right: 0,
            textAlign: "center",
            zIndex: 9,
            visibility: loading ? "hidden" : "visible",
            width: predictorWidth,
            height: predictorHeight,
          }}
        />

        <div
          className="Demo-overlay"
          style={{
            width: predictorWidth,
            height: predictorHeight,
          }}
        >
          {!(loading || countdownStarted) && (
            <div className="info">
              {timerStarted ? (
                <span>
                  {exerciseTimer > 0 ? <div className="timer">{formatTimer(exerciseTimer)}</div> : null}

                  {exerciseTimer === 0 ? (
                    <span className="timer-results">
                      <h6>Weight:</h6>
                      <span>{user.weight} lbs</span>
                      <h6>Height:</h6>
                      <span>{user.height}</span>
                      <h6>{exerciseTimer > 0 ? "Repetitions" : "Total Reps"}:</h6>
                      <span>{repCounter}</span>
                      {isPowerUsed ? (
                        <div>
                          <h6>{"Avg. Power"}</h6>
                          <span>{powerCounter.avgPower}</span>
                          <h6>{"Peak. Power"}:</h6>
                          <span>{powerCounter.peakPower}</span>
                        </div>
                      ) : null}
                      <button style={{ marginTop: 10 }} onClick={() => reset()}>
                        Restart
                      </button>
                    </span>
                  ) : (
                    <div>
                      <span className="timer-results border-top">
                        <h6>Repetitions</h6>
                        <span>{repCounter}</span>
                      </span>
                      <span className="timer-results border-top">
                        <span className="user-position">{positionText}</span>
                        <span className="user-position">{raisedHandPosition.includes("Up") && "Hand Raised"}</span>
                      </span>
                    </div>
                  )}
                </span>
              ) : null}

              {!timerStarted && !countdownStarted && !snapshotStarted ? <button onClick={() => startTimer()}>Start Exercise</button> : null}
              {/* <button className="mt-2" onClick={() => snapDisplacement()}>Displace</button> */}
            </div>
          )}
          {loading ? null : (
            <div className="skeleton toggle">
              <div className="form-check form-switch">
                <input className="form-check-input" type="checkbox" role="switch" checked={showSkeleton} id="skelToggle" onChange={handleShowSkeletonChange} />
                <label className="form-check-label" htmlFor="skelToggle">
                  Show Keypoints & Skeleton
                </label>
              </div>
            </div>
          )}
        </div>
      </header>
      <h1
        style={{
          position: "absolute",
          marginLeft: "auto",
          marginRight: "auto",
          color: "white",
          left: 0,
          right: 0,
          top: 0,
          textAlign: "center",
          fontSize: 42,
          zIndex: 10,
        }}
      >
        {exerciseName === "N/A" ? "Launching exercise..." : "Smyl Fitness - " + exerciseName}
      </h1>
      {/* <h2 style={{
        position: 'absolute', top: 60, width: '100%', color: 'grey'
      }}>The exercise will begin after a 5 second countdown.</h2> */}
      {/* <div className="Select-Demo">
        <select onChange={selectExercise}>
          <option value="sit-stand">Sit and Stand</option>
          <option value="march">March In Place</option>
        </select>
      </div> */}
      {/* <video id="exerciseVideo" controls></video> */}
    </div>
  );
}

export default Tracker;

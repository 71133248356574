import { Exercise } from "./Exercise";
import { Position } from "../models";

// Detectable positions for exercise Right Hand Raise
const POSITIONS: Position[] = [
  // Right Hand Up Position
  {
    name: "rightHandUp",
    detected: false,
    constraints: [
      {
        type: "y-axis",
        points: ["right_wrist", "nose"],
        operation: "greater than",
      },
    ],
  },
  // Right Hand Down Position
  {
    name: "rightHandDown",
    detected: false,
    constraints: [
      {
        type: "y-axis",
        points: ["right_wrist", "nose"],
        operation: "less than",
      },
    ],
  },
];

export class RightHandRaise extends Exercise {

  constructor() {
    super({
      id: 0,
      name: "Right Hand Raise",
      steps: "rightHandDown,rightHandUp",
      positions: POSITIONS,
      isPowerUsed: false,
      duration: 30,
      countdown: 5
    })
  }

}
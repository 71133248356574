import { Exercise } from "./Exercise";
import { Position } from "../models";

// Detectable positions for exercise Left Hand Raise
const POSITIONS: Position[] = [
  // Left Hand Up Position
  {
    name: "leftHandUp",
    detected: false,
    constraints: [
      {
        type: "y-axis",
        points: ["left_wrist", "nose"],
        operation: "greater than",
      },
    ],
  },
  // Left Hand Down Position
  {
    name: "leftHandDown",
    detected: false,
    constraints: [
      {
        type: "y-axis",
        points: ["left_wrist", "nose"],
        operation: "less than",
      },
    ],
  },
];

export class LeftHandRaise extends Exercise {

  constructor() {
    super({
      id: 0,
      name: "Left Hand Raise",
      steps: "leftHandDown,leftHandUp",
      positions: POSITIONS,
      isPowerUsed: false,
      duration: 30,
      countdown: 5
    })
  }

}
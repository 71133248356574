import { Keypoint, Pose } from "@tensorflow-models/pose-detection";
import { Constraint, ExerciseConfig, Position } from "../models";
import { PoseData, PosePredictor as Predictor } from "./PosePredictor";



export class Exercise {

  // Exercise ID (from database)
  id!: number;

  // Exercise name, e.g. "Sit to Stand"
  name: string;

  // Exercise steps, e.g. ["sitting", "standing"]
  steps: string[];

  // Exercise's detectable positions
  positions: Position[];

  // Exercise displacement snapshot
  displacement?: Pose;

  // Is power calculated
  isPowerUsed: boolean = false;

  // Exercise duration and countdown
  duration: number = 0;
  countdown: number = 0;

  constructor(config: ExerciseConfig) {
    // console.log(config)
    this.id = config.id;
    this.name = config.name;
    this.steps = config.steps.split(',');
    this.positions = config.positions;
    this.isPowerUsed = config.isPowerUsed;
    this.duration = config.duration;
    this.countdown = config.countdown;
  }

  // Paper - Simple equations to predict concentric lower-body muscle power in older adults using the 30-second chair-rise test: a pilot study
  // Weight entered in pounds (lbs)
  static calculateDistance(reps: number, height: number, pose: Pose | null): number {
    if (!pose) return 0;
    // console.log("x.displacement: ", displacement);
    // const weightKg: number = weight / 2.20462;
    // console.log("Pose: ", pose);
    const { femurLengthL, femurLengthR, leftEar, rightEar, leftAnkle, rightAnkle, leftShoulder, rightShoulder  } = Predictor.data(pose) as PoseData;
    // console.log("Femur length: ", femurLengthL);
    const avgtopTorso = (leftShoulder.y + rightShoulder.y) / 2;
    const avgFemurLength = (femurLengthL + femurLengthR) / 2;
    // console.log("Femur length: ", avgFemurLength);
    const avgEarY = (leftEar.y + rightEar.y) / 2;
    const addConst = Math.abs(avgtopTorso - avgEarY)
    const avgAnkleY = (leftAnkle.y + rightAnkle.y) / 2;
    const compHeight = Math.abs(avgAnkleY - avgEarY) ; 
    // console.log("weightKg: ", weightKg);
    // console.log("Midfemur Length:", (height/compHeight)*avgFemurLength)
    // Average power (W) = −504.845 + 10.793 body weight (kg)  + 21.603 stands in 20s (R2 = 0.784, P , 0.01)
    // Peak power (W) = −715.218 + 13.915 body weight (kg) + 33.425 stands in 20s (R2 = 0.811, P , 0.01)
    // const avgPower = -504.845 + 10.793 * weightKg + 21.603 * reps;
    // const peakPower = -715.218 + 13.915 * weightKg + 33.425 * reps;

    // const avgPowerRounded = Math.round(avgPower * 100) / 100;
    // const peakPowerRounded = Math.round(peakPower * 100) / 100;
    return (height/compHeight) * avgFemurLength;

    
  };

  private static checkConstraint(keypoints: Keypoint[], constraint: Constraint, displacement: Pose | null) {
    switch (constraint.type) {
      case "angle":
        // get angle data from keypoints
        const data = Exercise.getAngleData(keypoints, constraint.points);
    
        // Get Min Confidence Score
        if (data.score < 0.5) return false;
    
        if (constraint.operation === "greater than")
          return data.angle > (constraint.threshold as number);
  
        else if (constraint.operation === "less than")
          return data.angle < (constraint.threshold as number);
        break;
      case "y-axis":
        const pos0: any = Exercise.getKeypointPosition(keypoints, constraint.points[0]);
        const pos1: any = Exercise.getKeypointPosition(keypoints, constraint.points[1]);
    
        // console.log(`Positions for ${constraint.points[0]} `, pos0);
        // console.log(`Positions for ${constraint.points[1]} `, pos1);

        // Discard key if score is less than 0.3
        if(pos0.score < 0.5 || pos1.score < 0.5) return false;
    
        if (constraint.operation === "greater than")
          return pos1.y > pos0.y;
        else if (constraint.operation === "less than")
          return pos1.y < pos0.y
        break;
      case "mid femur":
        if (!displacement) return false;
        const { femurLengthL, femurLengthR, rightHip, leftHip } = Predictor.data(displacement) as PoseData;
        const pos: any = Exercise.getKeypointPosition(keypoints, constraint.points[0]);
        
        // Percentage of displacement
        let threshold = constraint.threshold;


        if (pos.score < 0.5) return false;
        // console.log('midpoint: ', midFemurAvg, pos, constraint.points[0], constraint.operation,
        //   constraint.operation === 'less than' ? midFemurAvg < pos.y : midFemurAvg > pos.y
        // );
    
//  0

// hip (y) at standing 350

// 375 

// 400 - midFemurAvg
// knee (y) at standing 450

//  hip + (knee - hip) *.25 

// midFemur = hip + (knee-hip)*.5





// 600


        const femurLY = Number(leftHip.y) + Number(femurLengthL) *(Number(threshold)*.01); 
        const femurRY = Number(rightHip.y) + Number(femurLengthR) *(Number(threshold)*.01); 

        // console.log("femurLY: ", leftHip.y, femurLengthL, threshold, femurLY);
        // console.log("femurRY: ", rightHip.y, femurLengthR, threshold, femurRY);

        if (constraint.operation === "greater than")
          return femurLY > pos.y;

        else if (constraint.operation === "less than")
          return femurLY < pos.y
        break;
      default:
        break;
    }

    
    // if (constraint.type === "angle") {

    //   // return evaluateAngleConstraint(keypoints, constraint);
    // } else if (constraint.type === "y-axis") {
    //   // return evaluateYAxisConstraint(keypoints, constraint);
    // }
  };

  public static constraintCheck(
    keypoints: Keypoint[],
    constraints: Constraint[],
    displacement: Pose | null
  ): boolean {
    for (const constraint of constraints)
      // Evaluate constraint
      if (!Exercise.checkConstraint(keypoints, constraint, displacement)) return false;

    return true;
  };

  static getAngleData(keypoints: Keypoint[], anglePoint: string[]): {
    angle: number; score: number;
  } {
    /*
     * Calculates the angle ABC in radians and converts to degrees
     *
     * A first point, ex: {x: 0, y: 0}
     * C second point
     * B center point
     */
    function find_angle(
      A: { x: any; y: any; score: any },
      B: { x: number; y: number; score: number },
      C: { x: number; y: number; score: number }
    ): number {
      var AB = Math.sqrt(Math.pow(B.x - A.x, 2) + Math.pow(B.y - A.y, 2));
      var BC = Math.sqrt(Math.pow(B.x - C.x, 2) + Math.pow(B.y - C.y, 2));
      var AC = Math.sqrt(Math.pow(C.x - A.x, 2) + Math.pow(C.y - A.y, 2));
  
      const radians = Math.acos((BC * BC + AB * AB - AC * AC) / (2 * BC * AB));
      const degrees = (radians * 180) / Math.PI;
      return degrees;
    }

    const pos0: any = Exercise.getKeypointPosition(keypoints, anglePoint[0]);
    const pos1: any = Exercise.getKeypointPosition(keypoints, anglePoint[1]);
    const pos2: any = Exercise.getKeypointPosition(keypoints, anglePoint[2]);
    const score = Math.min(pos0.score, pos1.score, pos2.score);
    const angle = find_angle(pos0, pos1, pos2);

    return { angle, score }; // return angle in degrees, lowest keypoint score
  };

  static getKeypointPosition(keypoints: Keypoint[], partName: string): {
    x: number;
    y: number;
    score: number | undefined;
  } | {
    x?: undefined;
    y?: undefined;
    score?: undefined;
  } {
    for (const keypoint of keypoints) {
      const test: any = keypoint.name;
      if (keypoint.name === partName || partName.indexOf(test) > -1) {
        const { x, y, score } = keypoint;
        return { x, y, score };
      }
    }
    return {};
  };

}
import { Exercise } from "./Exercise";
import { Position } from "../models";

// Detectable positions for exercise March In Place
const POSITIONS: Position[] = [
  // Standing Position
  {
    name: "standing",
    detected: false,
    constraints: [
      {
        type: "angle",
        operation: "greater than",
        points: ["left_shoulder", "left_hip", "left_knee"],
        threshold: 150,
      },
      {
        type: "angle",
        operation: "greater than",
        points: ["left_hip", "left_knee", "left_ankle"],
        threshold: 175,
      },
      {
        type: "angle",
        operation: "greater than",
        points: ["right_shoulder", "right_hip", "right_knee"],
        threshold: 150,
      },
      {
        type: "angle",
        operation: "greater than",
        points: ["right_hip", "right_knee", "right_ankle"],
        threshold: 175,
      },
      
    ],
  },
  // {
  //   name: "not_standing",
  //   detected: false,
  //   constraints: [
  //     {
  //       type: "angle",
  //       operation: "less than",
  //       points: ["left_shoulder", "left_hip", "left_knee"],
  //       threshold: 150,
  //     },
  //     {
  //       type: "angle",
  //       operation: "less than",
  //       points: ["left_hip", "left_knee", "left_ankle"],
  //       threshold: 150,
  //     },
  //     {
  //       type: "angle",
  //       operation: "less than",
  //       points: ["right_shoulder", "right_hip", "right_knee"],
  //       threshold: 150,
  //     },
  //     {
  //       type: "angle",
  //       operation: "less than",
  //       points: ["right_hip", "right_knee", "right_ankle"],
  //       threshold: 150,
  //     },
      
  //   ],
  // },
];

export class StandingPosition extends Exercise {

  constructor() {
    super({
      id: 0,
      name: "Standing Exercise",
      steps: "standing",
      positions: POSITIONS,
      isPowerUsed: false,
      duration: 120,
      countdown: 10
    })
  }

}
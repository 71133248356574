import { ExerciseConfig } from "../models";

export class ExerciseService {

  static exercises: ExerciseConfig[] = [];
  static loaded = false;
  
  static loadExerciseFromCache() {
    // Get from local storage if not found
    let configString = localStorage.getItem("exercise");

    if(!configString){
      return false
    }

    this.exercises = [JSON.parse(configString) as ExerciseConfig]

    return JSON.parse(configString)


  }

  setExercise(exercise: ExerciseConfig) {
    localStorage.setItem("exercise", JSON.stringify(exercise));
  }
}

import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import "../css/Home.scss";
import { ExerciseService } from "../tsx/ExerciseService";
import { apiUrl} from '../tsx/Constant'

export default function Home() {
  const [contin, setContin] = useState(false);
  const [weight, setWeight] = useState(0);
  const [gender, setGender] = useState("male");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [height, setHeight] = useState(0);
  const [exerciseId, setExerciseId] = useState(0);
  const [exercises, setExercises] = useState(ExerciseService.exercises);

  function handleWeightChange(event: { target: { value: any } }) {
    setWeight(event.target.value);
  }

  function handleFirstNameChange(event: { target: { value: any } }) {
    setFirstName(event.target.value);
  }

  function handleLastNameChange(event: { target: { value: any } }) {
    setLastName(event.target.value);
  }

  function handleEmailChange(event: { target: { value: any } }) {
    setEmail(event.target.value);
  }

  function handleGenderChange(event: { target: { value: any } }) {
    setGender(event.target.value);
  }

  function handleHeightChange(event: { target: { value: any } }) {
    setHeight(event.target.value);
  }

  function handleExerciseChange(event: { target: { value: any } }) {
    setExerciseId(event.target.value);
  }

  useEffect(() => {
    // if (!ExerciseService.exercises.length) {
      let apiurl = `${apiUrl}/exercise/list`;

      axios
        .get(apiurl)
        .then((response) => {
          console.log("Get Exercises response: ", response.data.exercises);
          const EXERCISES = response.data.exercises;
          ExerciseService.exercises = EXERCISES;
          setExercises(EXERCISES);
        })
        .catch((err) => {
          console.log("Error getting exercises: ", err);
        });
    // }
  }, []);

  return (
    <main>
      <div className="branding">
        <h1 style={{ textAlign: "center" }}>
          <img className="logo" src="logo.png" alt="" />
        </h1>
        <h2 style={{ textAlign: "center" }}>Welcome to the Smyl Fitness Exercise Demo</h2>
        {!contin && <h4 style={{ textAlign: "center" }}>Please enter the following configurations to begin your exercise.</h4>}
        {contin ? (
          <div className="instructions">
            {/* {exerciseId.toString() === "1" && <img src="SMYL_SitToStandPositionPreview.gif" alt="" />} */}
            {exerciseId.toString() === "1" && <iframe title="vimeo-player" src="https://player.vimeo.com/video/519713756?h=e2f2f49512" width="640" height="360" frameBorder="0" allowFullScreen></iframe>}
            {exerciseId.toString() === "2" && <iframe title="vimeo-player" src="https://player.vimeo.com/video/519713586?h=30175ad727" width="640" height="360" frameBorder="0" allowFullScreen></iframe>}
            
            {/* {exerciseId.toString() === "2" && <img src="SMYL_StepPositionPreview_ShortTransitionV2.gif" alt="" />} */}
            <button type="button" className="btn btn-primary btn-lg">
              <Link to="/tracker" state={{ firstName, lastName, email, gender, weight, height, exerciseId }}>
                Begin
              </Link>
            </button>
            <button type="button" className="btn btn-muted btn-lg mt-2" onClick={() => setContin(false)}>
              Cancel
            </button>
          </div>
        ) : (
          <div className="config-form text-center">
            <form>
              <div className="form-group">
                <label>First Name </label>
                <input type="text" className="form-control " placeholder="Enter your first name..." onChange={handleFirstNameChange} />
              </div>
              <div className="form-group">
                <label>Last Name </label>
                <input type="text" className="form-control " placeholder="Enter your last name..." onChange={handleLastNameChange} />
              </div>
              <div className="form-group">
                <label>Email </label>
                <input type="email" className="form-control " placeholder="Enter your email..." onChange={handleEmailChange} />
              </div>
              <div className="form-group">
                <label>Gender</label>
                <select className="form-control" placeholder="Select your gender..." onChange={handleGenderChange}>
                  <option>Select your gender...</option>
                  <option value={"male"}>Male</option>
                  <option value={"female"}>Female</option>
                </select>
              </div>
              <div className="form-group">
                <label>Weight (Lbs)</label>
                <input type="number" className="form-control " placeholder="Enter your weight..." onChange={handleWeightChange} />
              </div>
              <div className="form-group">
                <label>Height (in)</label>
                <select className="form-control" placeholder="Select your height..." onChange={handleHeightChange}>
                  <option>Select your height...</option>
                  <option>5'</option>
                  <option>5' 1"</option>
                  <option>5' 2"</option>
                  <option>5' 3"</option>
                  <option>5' 4"</option>
                  <option>5' 5"</option>
                  <option>5' 6"</option>
                  <option>5' 7"</option>
                  <option>5' 8"</option>
                  <option>5' 9"</option>
                  <option>5' 10"</option>
                  <option>5' 11"</option>
                  <option>6'</option>
                  <option>6' 1"</option>
                  <option>6' 2"</option>
                  <option>6' 3"</option>
                  <option>6' 4"</option>
                  <option>6' 5"</option>
                  <option>6' 6"</option>
                  <option>6' 7"</option>
                  <option>6' 8"</option>
                  <option>6' 9"</option>
                  <option>6' 10"</option>
                  <option>6' 11"</option>
                  <option>7'</option>
                </select>
              </div>
              <div className="form-group">
                <label>Exercise</label>
                <select className="form-control" placeholder="Select your exercise..." onChange={handleExerciseChange}>
                  <option>Select your exercise...</option>
                  {exercises.map((EX, i) => (
                    <option key={i} value={EX.id}>
                      {EX.name}
                    </option>
                  ))}
                </select>
              </div>
              <button type="button" className="btn btn-primary btn-lg" disabled={!exerciseId} onClick={() => setContin(true)}>
                Continue
              </button>
            </form>
          </div>
        )}
      </div>
    </main>
  );
}
